/** @jsx jsx */
import { jsx, Styled } from "theme-ui"

import { Layout, SEO } from "../components"
import {   
  Container
} from "../components"
const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <Styled.h1 sx={{ textAlign: 'center'}}>That page can't be found</Styled.h1>
    </Container>    
  </Layout>
)

export default NotFoundPage
